import React from "react";

export default class GooglePlay extends React.Component {
  componentDidMount() {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.starform.metalstorm"
    );
  }

  render() {
    return <div />;
  }
}
